import React, { FC } from 'react'
import styled from 'styled-components'
import Link from '../link/Link'
import { IFooterGroupedItems } from '../../typings/footer'
import { ScreenBreakpointSize } from '../../utils/enums'
import { getScreenBreakpointSize } from '../../utils/getScreenBreakpointSize'
import { FontWeight500, FontWeight700 } from '../../styles/variables'

interface IProps {
  columnData: IFooterGroupedItems[]
}

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const CollectionContainerStyled = styled.div`
  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    border-top: 1px solid var(--border-gray);
    padding-top: var(--spacing-24);
    padding-bottom: var(--spacing-24);

    &[data-hide-top-border='true'] {
      border-top: none;
    }

    [data-name='title'] {
      color: var(--body-gray);
      font-size: var(--font-size-16);
      font-weight: ${FontWeight700};
      line-height: var(--line-height-1_6);
    }
  }
`
const TitleStyled = styled.div`
  color: var(--black);
  font-size: var(--font-size-16);
  font-weight: ${FontWeight700};
  line-height: var(--line-height-1_6);
  margin-bottom: var(--spacing-8);
`
const SingleItemContainerStyled = styled.div`
  display: flex;
  align-items: center;

  &[data-is-last-item='true'] {
    [data-name='title-without-link'] {
      line-height: var(--line-height-2_6);
    }
  }
`
const LinkStyled = styled(Link)`
  color: var(--color-primary);
  display: block;
  font-size: var(--font-size-16);
  font-weight: ${FontWeight500};
  line-height: var(--line-height-2_4);
`
const TextWithoutLinkStyled = styled.div`
  color: var(--heading-gray);
  font-size: var(--font-size-16);
  font-weight: ${FontWeight500};
  line-height: var(--line-height-1_5);
  margin-top: var(--spacing-2);
  max-width: 362px;
`
const AdditionalTextStyled = styled.div`
  background-color: var(--corn);
  border-radius: var(--border-radius-2);
  color: var(--black);
  font-size: var(--font-size-10);
  font-weight: ${FontWeight700};
  margin-left: var(--spacing-8);
  padding: var(--spacing-2) var(--spacing-4) 0;
`

const FooterColumn: FC<IProps> = ({ columnData }) => {
  return (
    <ContainerStyled>
      {columnData.map(({ collectionTitle = '', items }, index) => {
        return (
          <div key={index} className={columnData.length > 1 && index === 0 ? 'mb-15' : ''}>
            <CollectionContainerStyled key={index}>
              {collectionTitle && <TitleStyled>{collectionTitle}</TitleStyled>}
              {items.map(
                (
                  { title = '', url = '', additionalText = '', renderableChildren = null, isLastItem = false },
                  index,
                ) => {
                  return (
                    <SingleItemContainerStyled key={index} data-is-last-item={isLastItem}>
                      {title &&
                        (url ? (
                          <LinkStyled to={url}>{title}</LinkStyled>
                        ) : (
                          <TextWithoutLinkStyled data-name="title-without-link">{title}</TextWithoutLinkStyled>
                        ))}
                      {additionalText && <AdditionalTextStyled>{additionalText}</AdditionalTextStyled>}
                      {renderableChildren}
                    </SingleItemContainerStyled>
                  )
                },
              )}
            </CollectionContainerStyled>
          </div>
        )
      })}
    </ContainerStyled>
  )
}

export default FooterColumn
