import * as React from 'react'
import { UserPreferencesContextProvider } from '../context/userPreferencesContext'
import Footer from '../components/footer/footer'
import { AppDownloadsDataContextProvider } from '../context/appDownloadsDataContext'
import AppDownloadsDataContextTopComponent from './appDownloadsDataContextTopComponent'
import Header from '../components/header/header'
import { createGlobalStyle } from 'styled-components'
import { ScreenBreakpointSize } from '../utils/enums'
import { getScreenBreakpointSize } from '../utils/getScreenBreakpointSize'

const GlobalStyle = createGlobalStyle`
  #application-wrapper {
    padding: 0;
  }

  #content-wrapper {
    margin-top: 105px;
    margin-bottom: var(--spacing-72);


    @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.ExtraLarge)}) {
      margin-top: 107px;
      margin-bottom: var(--spacing-48);
    }

    @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Small, -1)}) {
      margin-top: 90px;
    }

    &[data-is-full-page='true'] {
      margin-top: 0;
    }
  }
`

/**
 * This layout is rendered by https://www.gatsbyjs.com/plugins/gatsby-plugin-layout/, which is largely a legacy concept.
 */
const Layout = ({
  children,
  pageContext: { layout },
}: {
  children: React.ReactNode
  pageContext: { layout: string | undefined }
}): JSX.Element => {
  if (layout === 'full-page') {
    return (
      <>
        <GlobalStyle />
        <div id="application-wrapper">
          <AppDownloadsDataContextProvider>
            <AppDownloadsDataContextTopComponent>
              <div id="content-wrapper" data-is-full-page={true}>
                {children}
              </div>
            </AppDownloadsDataContextTopComponent>
          </AppDownloadsDataContextProvider>
        </div>
      </>
    )
  }

  return (
    <>
      <GlobalStyle />
      <div id="application-wrapper">
        <AppDownloadsDataContextProvider>
          <AppDownloadsDataContextTopComponent>
            <Header />
            <div id="content-wrapper">
              <UserPreferencesContextProvider>{children}</UserPreferencesContextProvider>
            </div>
            <Footer />
          </AppDownloadsDataContextTopComponent>
        </AppDownloadsDataContextProvider>
      </div>
    </>
  )
}

export default Layout
