import { RefObject, useEffect } from 'react'

interface IParams {
  headerTopMostElementRef: RefObject<HTMLDivElement>
  setShouldShowAllHeaderItems: (prevState: boolean) => void
}

const useControlHeaderItems = ({ headerTopMostElementRef, setShouldShowAllHeaderItems }: IParams): void => {
  useEffect(() => {
    if (!headerTopMostElementRef.current) {
      return
    }

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setShouldShowAllHeaderItems(false)
        } else {
          setShouldShowAllHeaderItems(true)
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection)
    observer.observe(headerTopMostElementRef.current)
  }, [headerTopMostElementRef, setShouldShowAllHeaderItems])
}

export default useControlHeaderItems
