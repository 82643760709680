import { SubscriptionName } from '@standardnotes/common'
import { AnyFeatureDescription } from '@standardnotes/features'

export type MarketingSubscriptionPeriod = 'month' | 'year'

export type SubscriptionPeriod = 'month' | 'year' | '2 years' | '5 years'

export type PaidSubscriptionPlanNames = SubscriptionName.PlusPlan | SubscriptionName.ProPlan

export const FreeSubscriptionPlanName = 'CORE_PLAN'

export type FreeSubscriptionPlan = typeof FreeSubscriptionPlanName

export type DisplayableSubscriptionPlanIdentifier = PaidSubscriptionPlanNames | FreeSubscriptionPlan

export type TSupportedInfo = boolean | string | number | string[]

export type TSupportedInfoItemsCollection = { [key in DisplayableSubscriptionPlanIdentifier]: TSupportedInfo }

export type TSupportedInfoItem = TSupportedInfoItemsCollection | TSupportedInfo

export interface IFeature {
  name: string
  infoText?: string
  isSupportedInfo: TSupportedInfoItem
}

export const SubscriptionDisplayName: Record<DisplayableSubscriptionPlanIdentifier, string> = {
  CORE_PLAN: 'Standard',
  PLUS_PLAN: 'Productivity',
  PRO_PLAN: 'Professional',
}

export interface IFeaturesCollection {
  title: string
  features: IFeature[]
}

export const DefaultPPP: RemotePPP = {
  country: 'United States',
  emoji: '🇺🇸',
}

export type RemotePPP = {
  country: string
  emoji: string
  callout?: boolean
  no_monthly?: boolean
}

export type RemotePlan = {
  name: string
  pricing: RemotePlanPricingOption[]
  features: AnyFeatureDescription[]
  ppp: RemotePPP
}

export function isMonthlyPricingAvailable(lookup: RemotePlanLookup): boolean {
  const plans = Object.values(lookup)
  return plans.some((p) => doesPlanOfferMonthly(p))
}

export function doesPlanOfferMonthly(plan: RemotePlan): boolean {
  return plan.pricing.find((p) => p.period === 'month' && p.price > 0) != undefined
}

export const getPricingForPeriod = (
  plan: RemotePlan,
  period: SubscriptionPeriod,
): RemotePlanPricingOption | undefined => {
  return plan.pricing.find((p) => p.period === period)
}

export function formatPriceForDisplay(pricing: RemotePlanPricingOption): string {
  return `$${pricing.price.toFixed(2)}/${pricing.period}`
}

export type RemotePlanCoupon = {
  code: string
  discount: number
}

export type RemotePlanPricingOption = {
  price: number
  period: SubscriptionPeriod
  original_price?: number
  ppp: RemotePPP
  coupon?: RemotePlanCoupon
}

export type RemotePlanLookup = {
  [key in DisplayableSubscriptionPlanIdentifier]: RemotePlan
}
