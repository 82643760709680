import { SubscriptionDisplayName } from './../typings/plans'

export const ChoosePricingPlanButtonText = {
  ChooseCore: 'Start for free',
  GoPlus: `Go ${SubscriptionDisplayName.PLUS_PLAN}`,
  GoPro: `Go ${SubscriptionDisplayName.PRO_PLAN}`,
  ChoosePlus: `Choose ${SubscriptionDisplayName.PLUS_PLAN}`,
  ChoosePro: `Choose ${SubscriptionDisplayName.PRO_PLAN}`,
}

export enum FeatureCategories {
  Styling = 'Styling',
  NoteTypes = 'Note types',
  Security = 'Security',
  OtherFeatures = 'Other features',
}

export enum CustomFeatureNames {
  Themes = 'Themes',
  PlainText = 'Plain text',
  Super = 'Super notes',
  Spreadsheet = 'Spreadsheet',
  Authenticator = 'Authenticator',
  TwoFactorAuth = 'Two factor authentication',
  EncryptedFiles = 'Encrypted files',
  EmailBackups = 'Email backups',
  NoteHistory = 'Note history',
  ListedCustomDomain = 'Listed custom domain',
  Files = 'Files',
  TagNesting = 'Folders',
  SmartFilters = 'Smart filters',
  SignInAlerts = 'Account sign-in email alerts',
  EndToEndEncryption = 'End to end encryption',
  SyncOnAllDevices = 'Sync multiple devices',
}

export enum ScreenBreakpointSize {
  ExtraSmall = 320,
  Small = 600,
  Medium = 768,
  Large = 992,
  ExtraLarge = 1200,
}

export enum ChangePlanPane {
  PlanSelect,
  ConfirmChange,
}

export enum SubInfoPane {
  Main,
  ConfirmCancel,
  ChangePlan,
  EmailLastInvoice,
  UpdatePaymentMethod,
  ResendCode,
}
