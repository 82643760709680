import { useContext, useEffect, useState } from 'react'
import AppDownloadsDataContext from '../context/appDownloadsDataContext'
import { getCurrentPlatformAndBrowser } from '../utils/getCurrentPlatformAndBrowser'
import { DownloadsWithUserRecommendedDownload } from '../data/Downloads'
import { getPlatformsIdToDataMapper } from '../utils/getPlatformsIdToDataMapper'
import { getDownloadData } from '../utils/getDownloadData'

const useFetchDownloadsData = (): void => {
  const { updateAppDownloadsData } = useContext(AppDownloadsDataContext)

  const [isAllDownloadsDataFetched, setIsAllDownloadsDataFetched] = useState(false)
  const [currentPlatformItemIndex, setCurrentPlatformItemIndex] = useState<number | undefined>()

  const { currentBrowser, currentPlatformName } = getCurrentPlatformAndBrowser()

  useEffect(() => {
    const fetchPlatformDownloads = async () => {
      if (!currentPlatformName || !currentBrowser || typeof currentPlatformItemIndex !== 'undefined') {
        return
      }

      try {
        const response = DownloadsWithUserRecommendedDownload(currentBrowser, currentPlatformName)
        const { downloads, list, platformItem } = response

        if (!platformItem) {
          return
        }

        const platformsIdToDataMapper = getPlatformsIdToDataMapper()

        const platformItems = getDownloadData({
          downloads: list,
          platformsIdToDataMapper,
        })
        const platformItemIndex = platformItems.findIndex(
          (singlePlatformItem) => platformItem.platform === singlePlatformItem.id,
        ) as number
        setCurrentPlatformItemIndex(platformItemIndex)

        updateAppDownloadsData({
          downloads,
          list: list,
          platformItem,
          currentPlatformItemIndex: platformItemIndex,
        })

        setIsAllDownloadsDataFetched(true)
      } catch (error) {
        console.error(error)
      }
    }

    if (!isAllDownloadsDataFetched) {
      void fetchPlatformDownloads()
    }
  }, [currentBrowser, currentPlatformItemIndex, currentPlatformName, isAllDownloadsDataFetched, updateAppDownloadsData])
}

export default useFetchDownloadsData
