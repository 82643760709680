import React, { FC, ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'
import { ArrowDownSmallIcon } from '../../assets/icons'

/*
  Implemented based on these sources:
  - https://blog.logrocket.com/how-to-build-a-react-accordion-menu-from-scratch/
  - https://css-tricks.com/using-css-transitions-auto-dimensions/
*/
interface IProps {
  title: string | JSX.Element
  className?: string
  isAlwaysExpanded?: boolean
  icon?: FC
  children?: ReactNode
}

const TitleContainerStyled = styled.div`
  position: relative;
`
const TitleWithIconContainerStyled = styled.div`
  display: flex;
  gap: var(--spacing-12);
`
const ArrowImgStyled = styled.img`
  position: absolute;
  right: 0;
  top: 0;

  &[data-is-expanded='true'] {
    transform: rotate(180deg);
  }
`
const DescriptionContainerStyled = styled.div`
  cursor: auto;
  overflow: hidden;
  transition: all 0.23s ease-out;
  transform-origin: top;
  transform: scaleY(0);

  &[data-expanded='true'] {
    height: auto;
    transform-origin: top;
    transform: none;
  }
`

const AccordionItem: FC<IProps> = ({ title, className = '', isAlwaysExpanded = false, icon, children }) => {
  const elementRef = useRef<HTMLDivElement | null>(null)
  const RenderableIcon = icon as FC

  const [isExpanded, setIsExpanded] = useState(isAlwaysExpanded)

  const expandedContainerHeight = isAlwaysExpanded ? 'auto' : elementRef.current?.scrollHeight || 0

  return (
    <div className={className}>
      <TitleContainerStyled
        data-name="title-container"
        onClick={() => {
          if (!isAlwaysExpanded) {
            setIsExpanded(!isExpanded)
          }
        }}
      >
        <TitleWithIconContainerStyled data-name={'title-with-icon-container'}>
          {icon && <RenderableIcon data-name={'accordion-item-icon'} />}
          <div data-name="title" data-is-always-expanded={isAlwaysExpanded}>
            {title}
          </div>
        </TitleWithIconContainerStyled>
        {!isAlwaysExpanded && (
          <ArrowImgStyled
            alt="Arrow Icon"
            data-name="arrow-icon"
            src={ArrowDownSmallIcon}
            data-is-expanded={isExpanded}
          />
        )}
      </TitleContainerStyled>
      <DescriptionContainerStyled
        data-name="expandable-section"
        data-expanded={isExpanded}
        ref={elementRef}
        style={isExpanded ? { height: expandedContainerHeight } : { height: 0 }}
      >
        {children}
      </DescriptionContainerStyled>
    </div>
  )
}

export default AccordionItem
