import React from 'react'
import Link from '../link/Link'
import { ArrowRightIcon } from '../../assets/icons'

interface IProps {
  text: string
  targetUrl: string
}

const TextWithLink = ({ text, targetUrl }: IProps): JSX.Element => {
  return (
    <Link to={targetUrl}>
      <span className="flex text-with-link-container">
        {text}
        <span className={'link-arrow-image-container'}>
          <img alt="Right Arrow Icon" src={ArrowRightIcon} className="right-arrow-icon" />
        </span>
      </span>
    </Link>
  )
}

export default TextWithLink
