import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import * as styles from './Link.module.scss'

export default function Link({
  to,
  isDisabled,
  shouldOpenTab,
  children,
  className,
}: {
  to: string
  isDisabled?: boolean
  shouldOpenTab?: boolean
  children: React.ReactNode
  className?: string
}): JSX.Element {
  const getClassName = () => `${styles.link} ${isDisabled ? styles.linkDisabled : ''} ${className ? className : ''}`

  const setProps = () => ({
    className: getClassName(),
    target: shouldOpenTab ? '_blank' : undefined,
    rel: shouldOpenTab ? 'noopener noreferrer' : undefined,
  })

  const isInternalLink = /^\/(?!\/)/.test(to)
  if (isInternalLink && !shouldOpenTab) {
    return (
      <GatsbyLink className={getClassName()} to={to}>
        {children}
      </GatsbyLink>
    )
  }

  const getHref = () => {
    return to
  }

  return (
    <a {...setProps()} href={getHref()}>
      {children}
    </a>
  )
}

Link.defaultProps = {
  isDisabled: false,
  shouldOpenTab: false,
}
