import React, { FC } from 'react'
import Link from '../link/Link'
import styled from 'styled-components'
import { ScreenBreakpointSize } from '../../utils/enums'
import { getScreenBreakpointSize } from '../../utils/getScreenBreakpointSize'
import { FontWeight500, FontWeight600 } from '../../styles/variables'

interface IProps {
  name: string
  url: string
  isActive?: boolean
  newTab?: boolean
  handleClick?: (...args: unknown[]) => unknown
  classes?: string
}

const ContainerStyled = styled.div`
  display: inline-block;
  margin-left: var(--spacing-36);

  font-size: var(--font-size-16);
  font-weight: ${FontWeight500};
  line-height: var(--line-height-1_375);

  a {
    color: var(--heading-gray);

    &:hover {
      border-bottom: 1px solid var(--corn);
      padding-bottom: 3px;
      text-decoration: none;
    }
  }

  &[data-is-active='true'] {
    border-bottom: 1px solid var(--corn);
    font-weight: ${FontWeight600};
    padding-bottom: var(--spacing-3);
    margin-top: var(--spacing-6);
    text-decoration: none;

    a {
      color: var(--black);
      cursor: default;
      text-decoration: none;
    }
  }

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    border-bottom: 1px solid var(--border-gray);
    display: block;
    margin-left: 0;

    &[data-is-active='true'] {
      border-bottom: 1px solid var(--border-gray);
      margin-top: 0;
      padding-bottom: 0;
    }

    a {
      width: 100%;

      &:hover {
        border-bottom: none;
        padding-bottom: var(--spacing-12);
      }
    }
  }

  @media screen and (min-width: ${getScreenBreakpointSize(
      ScreenBreakpointSize.Large,
      -1,
    )}) and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    margin-left: var(--spacing-24);
  }
`
const CustomLinkStyled = styled(Link)`
  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    display: inline-block;
    padding: var(--spacing-12) var(--spacing-16);
  }
`

const HeaderNavigationItem: FC<IProps> = ({
  name,
  url,
  isActive = false,
  newTab = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClick = () => {},
  classes = '',
}) => {
  return (
    <ContainerStyled data-is-active={isActive} className={classes} onClick={handleClick}>
      <CustomLinkStyled shouldOpenTab={newTab} to={`/${url}`}>
        {name}
      </CustomLinkStyled>
    </ContainerStyled>
  )
}

export default HeaderNavigationItem
