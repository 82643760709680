import React, { FC, useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import { getScreenBreakpointSize } from '../../utils/getScreenBreakpointSize'
import { ScreenBreakpointSize } from '../../utils/enums'
import { HamburgerMenuIcon } from '../../assets/icons'
import { HEADER_NAVIGATION_ITEMS } from '../../utils/constants'
import HeaderNavigationItem from './headerNavigationItem'
import { handleDownload } from '../../utils/handleDownload'
import TextWithLinkStyled from '../common/textWithLinkStyled'
import Button from '../common/button'
import AppDownloadsDataContext from '../../context/appDownloadsDataContext'
import useClickOutsideEventListener from '../../hooks/useClickOutsideEventListener'
import { IGetDownloadDataReturnItem } from '../../utils/getDownloadData'

interface IProps {
  path: string | undefined
}

const ContainerStyled = styled.div`
  margin-left: auto;
  right: var(--spacing-16);

  [data-name='title-container'] {
    top: 0;
    position: relative;
  }

  @media screen and (min-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large)}) {
    display: none;
  }
`

const MenuItemsContainerStyled = styled.div<{ isMenuExpanded: boolean }>`
  background-color: var(--white);
  border-top: 1px solid var(--border-gray);
  border-bottom: 2px solid var(--border-gray);
  position: absolute;
  left: calc(-1 * var(--spacing-16));
  top: var(--header-height-mobile);
  width: calc(100% + 2 * var(--spacing-16)); // paddings are taken into account

  transition: all 0.23s ease-out;
  transform: ${(props) => (props.isMenuExpanded ? 'none' : 'scaleY(0)')};
  transform-origin: top;
`
const CustomButtonStyled = styled(Button)<{ currentPlatformItemIndex: null | number }>`
  visibility: ${(props) => (props.currentPlatformItemIndex !== null ? 'visible' : 'hidden')};
  height: var(--spacing-36);
  line-height: 0.5;
  margin: var(--spacing-12) var(--spacing-16);

  padding-bottom: 12px;
`
const LinkContainerStyled = styled.div`
  border-top: 1px solid var(--border-gray);
  padding: var(--spacing-12) var(--spacing-16);

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    padding: 0;
  }
`
const CustomTextWithLinkStyled = styled(TextWithLinkStyled)`
  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    padding: var(--spacing-14) var(--spacing-16) var(--spacing-10);
  }
`

const HamburgerMenu: FC<IProps> = ({ path }) => {
  const { currentPlatformItemIndex, platformItem } = useContext(AppDownloadsDataContext)

  const containerRef = useRef(null)
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)

  useClickOutsideEventListener({
    elementRef: containerRef,
    callbackFn: () => setIsMenuExpanded(false),
  })

  return (
    <ContainerStyled ref={containerRef}>
      <img alt={'Menu Icon'} src={HamburgerMenuIcon} onClick={() => setIsMenuExpanded(!isMenuExpanded)} />
      <MenuItemsContainerStyled isMenuExpanded={isMenuExpanded}>
        {HEADER_NAVIGATION_ITEMS.filter(
          (navItemData) => process.env.ENABLE_UNFINISHED_FEATURES === 'true' || !navItemData.isUnfinishedFeature,
        ).map((navItemData) => {
          const { name, url } = navItemData
          return (
            <HeaderNavigationItem
              handleClick={() => setIsMenuExpanded(false)}
              key={name}
              name={name}
              url={url}
              isActive={url === path}
            />
          )
        })}
        {platformItem && (
          <CustomButtonStyled
            currentPlatformItemIndex={currentPlatformItemIndex}
            text="Download for free"
            handleClick={() => {
              setIsMenuExpanded(false)

              const platformId = (platformItem as IGetDownloadDataReturnItem).id

              handleDownload(platformItem.link as string, `/plans?downloaded=${platformId}`, platformId)
            }}
          />
        )}
        <LinkContainerStyled onClick={() => setIsMenuExpanded(false)}>
          <CustomTextWithLinkStyled text={'Go to web app'} targetUrl={'https://app.standardnotes.com'} />
        </LinkContainerStyled>
      </MenuItemsContainerStyled>
    </ContainerStyled>
  )
}

export default HamburgerMenu
