import React, { createContext, FC, ReactNode, useState } from 'react'
import { TUserPreferences } from '../typings/user_preferences'

interface IProps {
  children?: ReactNode
}

const userPreferencesDefaultValue: TUserPreferences = {
  billingFrequency: 'year',
}
const UserPreferencesContext = createContext({
  ...userPreferencesDefaultValue,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateUserPreferences: (_newPreferences: Partial<TUserPreferences>) => {},
})

UserPreferencesContext.displayName = 'UserPreferencesContext'

export default UserPreferencesContext

export const UserPreferencesContextProvider: FC<IProps> = ({ children }) => {
  const [userPreferences, setUserPreferences] = useState(userPreferencesDefaultValue)

  return (
    <UserPreferencesContext.Provider
      value={{
        ...userPreferences,
        updateUserPreferences: (newPreferences?: Partial<TUserPreferences>) => {
          setUserPreferences({ ...userPreferences, ...newPreferences })
        },
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  )
}
