export enum BrowserType {
  CHROME = 'chrome',
  FIREFOX = 'firefox',
  SAFARI = 'safari',
}

export enum OSType {
  ANDROID = 'android',
  IOS = 'ios',
  LINUX = 'linux',
  MACOS = 'mac-intel',
  MACOS_ARM = 'mac-arm',
  WINDOWS = 'windows',
  WEB = 'web',
}
