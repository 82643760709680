import styled from 'styled-components'
import { getScreenBreakpointSize } from '../utils/getScreenBreakpointSize'
import { ScreenBreakpointSize } from '../utils/enums'
import { FontWeight500, FontWeight700, FontWeight900 } from './variables'

export const PageTitleStyled = styled.h1`
  color: var(--black);
  font-family: var(--lato);
  font-size: var(--page-title-font-size);
  font-weight: ${FontWeight900};
  line-height: var(--line-height-1_3);
`

export const SecondaryHeaderStyled = styled.h2`
  color: var(--black);
  font-family: var(--lato);
  font-size: var(--font-size-30);
  font-weight: ${FontWeight700};
  line-height: var(--line-height-1_3);
`

export const PageMainDescriptionTextStyled = styled.h2`
  color: var(--body-gray);
  font-size: var(--section-font-size);
  font-weight: ${FontWeight500};
  line-height: var(--line-height-1_5);
`

export const DescriptionTextStyled = styled.div`
  color: var(--body-gray);
  font-size: var(--section-font-size);
  font-weight: ${FontWeight500};
  line-height: var(--line-height-1_5);
  max-width: 712px;

  @media screen and (min-width: ${getScreenBreakpointSize(
    ScreenBreakpointSize.Medium,
  )}) and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    max-width: 90%;
  }
}
`

export const PaddedContentStyled = styled.div`
  padding-left: calc(var(--desktop-padding) + calc(calc(100% - var(--content-width)) / 2));
  padding-right: calc(var(--desktop-padding) + calc(calc(100% - var(--content-width)) / 2));

  @media screen and (min-width: ${getScreenBreakpointSize(
      ScreenBreakpointSize.Medium,
    )}) and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.ExtraLarge, -1)}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

export const SectionTitleStyled = styled.div`
  color: var(--black);
  font-family: var(--lato);
  font-size: var(--font-size-33);
  font-weight: ${FontWeight700};
  line-height: var(--line-height-1_3);

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.ExtraLarge)}) {
    font-size: var(--font-size-24);
  }
`

export const SectionSubtitleStyled = styled.div`
  color: var(--black);
  font-family: var(--lato);
  font-size: var(--font-size-24);
  font-weight: ${FontWeight700};
  line-height: var(--line-height-1_3);

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.ExtraLarge)}) {
    font-size: var(--font-size-20);
  }
`

export const UppercasedTextStyled = styled.div`
  text-transform: uppercase;
`

export const SectionUppercasedTitleStyled = styled(UppercasedTextStyled)`
  color: var(--heading-gray);
  font-weight: ${FontWeight700};
  font-size: var(--font-size-13);
  line-height: var(--line-height-1_6);

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.ExtraLarge)}) {
    font-size: var(--font-size-12);
  }
`
