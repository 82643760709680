import { DROPDOWN_NAME_AND_OPTIONS_VERTICAL_DISTANCE } from './constants'
import { RefObject } from 'react'

interface IParams {
  dropdownContainerRef: RefObject<HTMLDivElement>
  dropdownOptionsRef: RefObject<HTMLDivElement>
}
export const getCanOpenDropdownOnBottom = ({ dropdownContainerRef, dropdownOptionsRef }: IParams): boolean => {
  let caOpenDropdownOnBottom = true
  const dropdownElementBoundingRect = dropdownContainerRef.current?.getBoundingClientRect()

  if (dropdownElementBoundingRect) {
    const dropdownOptionsHeight = dropdownOptionsRef.current?.clientHeight || 0

    if (
      dropdownElementBoundingRect.bottom + dropdownOptionsHeight + DROPDOWN_NAME_AND_OPTIONS_VERTICAL_DISTANCE >=
      window.innerHeight
    ) {
      caOpenDropdownOnBottom = false
    }
  }

  return caOpenDropdownOnBottom
}
