import React, { FC, useContext } from 'react'
import HeaderNavigationItem from './headerNavigationItem'
import styled from 'styled-components'
import Button from '../common/button'
import AppDownloadsDataContext from '../../context/appDownloadsDataContext'
import { handleDownload } from '../../utils/handleDownload'
import TextWithLinkStyled from '../common/textWithLinkStyled'
import { ScreenBreakpointSize } from '../../utils/enums'
import { getScreenBreakpointSize } from '../../utils/getScreenBreakpointSize'
import { HEADER_NAVIGATION_ITEMS } from '../../utils/constants'
import { Download } from '../../typings/download'
import { IGetDownloadDataReturnItem } from '../../utils/getDownloadData'

interface IProps {
  shouldShowAllHeaderItems: boolean
  path: string | undefined
}

const ContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    display: none;
  }
`
const RightSectionStyled = styled.div`
  display: flex;
  align-items: center;
`
const CustomButtonStyled = styled(Button)<{ shouldShowButton: boolean }>`
  visibility: ${(props) => (props.shouldShowButton ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.shouldShowButton ? 1 : 0)};
  height: var(--spacing-36);
  line-height: 0.5;
  transition: all ease-in 0.3s;
`
const LinkContainerStyled = styled.div`
  margin-left: var(--spacing-36);

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    margin-left: var(--spacing-24);
  }
`

const HeaderNavigation: FC<IProps> = ({ shouldShowAllHeaderItems, path }) => {
  const { currentPlatformItemIndex, platformItem } = useContext(AppDownloadsDataContext)

  return (
    <ContainerStyled>
      <div>
        {HEADER_NAVIGATION_ITEMS.filter(
          (navItemData) => process.env.ENABLE_UNFINISHED_FEATURES === 'true' || !navItemData.isUnfinishedFeature,
        ).map((navItemData) => {
          const { name, url } = navItemData
          return <HeaderNavigationItem key={name} name={name} url={url} isActive={url === path} />
        })}
      </div>
      <RightSectionStyled>
        <CustomButtonStyled
          shouldShowButton={shouldShowAllHeaderItems && currentPlatformItemIndex !== null}
          text="Download for free"
          handleClick={() => {
            const platformId = (platformItem as IGetDownloadDataReturnItem).id
            handleDownload(
              (platformItem as Partial<Download>).link as string,
              `/plans?downloaded=${platformId}`,
              platformId,
            )
          }}
        />
        <LinkContainerStyled>
          <TextWithLinkStyled text={'Go to web app'} targetUrl={'https://app.standardnotes.com'} />
        </LinkContainerStyled>
      </RightSectionStyled>
    </ContainerStyled>
  )
}

export default HeaderNavigation
