import React, { FC, ReactNode } from 'react'
import useFetchDownloadsData from '../hooks/useFetchDownloadsData'

interface IProps {
  children?: ReactNode
}

const AppDownloadsDataContextTopComponent: FC<IProps> = ({ children }) => {
  useFetchDownloadsData()

  return <>{children}</>
}

export default AppDownloadsDataContextTopComponent
