import React, { FC } from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'

type Props = {
  id?: string
  icon: string
  className?: string
}

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: var(--spacing-40);
  height: var(--spacing-40);
`
const CircleStyled = styled.div`
  position: absolute;
  width: var(--spacing-40);
  height: var(--spacing-40);
  background: var(--color-blue);
  border-radius: var(--spacing-30);
  opacity: 0.12;
`
const IconStyled = styled(SVG)`
  position: absolute;
  path {
    fill: var(--color-blue);
  }
  width: var(--spacing-20);
  height: var(--spacing-20);
`

const IconInCircle: FC<Props> = ({ id, icon, className = '' }) => {
  return (
    <ContainerStyled data-name={'icon-container'} data-id={id} className={className}>
      <CircleStyled data-name={'circle'} />
      <IconStyled src={icon} data-name={'icon'} />
    </ContainerStyled>
  )
}

export default IconInCircle
