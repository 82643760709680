import React from 'react'
import { IFooterGroupedItems } from '../../typings/footer'
import FooterDownloadSection from './footerDownloadSection'

export const footerData: IFooterGroupedItems[] = [
  {
    collectionTitle: 'Standard Notes',
    columnNumber: 0,
    items: [
      {
        title: 'Features',
        url: '/features',
      },
      {
        title: 'Pricing',
        url: '/plans',
      },
      {
        title: 'Blog',
        url: '/blog',
      },
      {
        title: 'Source code',
        url: 'https://github.com/standardnotes/app',
      },
    ],
    mobileOrder: 0,
  },
  {
    collectionTitle: 'Principles',
    columnNumber: 1,
    items: [
      {
        title: 'Stand For Privacy',
        url: '/privacy',
      },
      {
        title: 'Built to Last',
        url: '/longevity',
      },
      {
        title: 'Write Fearlessly',
        url: '/why-encrypted',
      },
      {
        title: 'Principles & Philosophies',
        url: '/principles',
      },
    ],
    mobileOrder: 1,
  },
  {
    collectionTitle: 'Use Cases',
    columnNumber: 0,
    items: [
      {
        title: 'Writers',
        url: '/usecase/writers',
      },
      {
        title: 'Legal Professionals',
        url: '/usecase/legal',
      },
      {
        title: 'Journalists',
        url: '/usecase/journalism',
      },
    ],
    mobileOrder: 1,
  },
  {
    collectionTitle: 'Resources',
    columnNumber: 2,
    items: [
      {
        title: 'Help and FAQ',
        url: '/help',
      },
      {
        title: 'Careers',
        url: 'https://proton.me/careers',
      },
      {
        title: 'Community Forum',
        url: 'https://standardnotes.com/forum',
      },
      {
        title: 'Knowledge Base',
        url: '/knowledge',
      },
      {
        title: 'Media & Press',
        url: '/press',
      },
      {
        title: 'Listed Blogging Platform',
        url: 'https://listed.to/',
      },
    ],
    mobileOrder: 3,
  },
  {
    collectionTitle: 'Compare',
    columnNumber: 1,
    items: [
      {
        title: 'vs Evernote',
        url: '/evernote-alternative',
      },
      {
        title: 'vs Google Keep',
        url: '/compare/google-keep-alternative',
      },
      {
        title: 'vs Simplenote',
        url: '/compare/simplenote-alternative',
      },
      {
        title: 'vs Obsidian',
        url: '/compare/obsidian-alternative',
      },
    ],
    mobileOrder: 1,
  },
  {
    collectionTitle: 'Articles',
    columnNumber: 2,
    items: [
      {
        title: `Best note taking app in ${new Date().getFullYear()}`,
        url: '/compare/best-note-taking-app',
      },
    ],
    mobileOrder: 1,
  },
  {
    collectionTitle: 'Follow Us',
    columnNumber: 3,
    items: [
      {
        title: 'X',
        url: 'https://x.com/StandardNotes',
      },
      {
        title: 'Discord',
        url: 'https://standardnotes.com/discord',
      },
      {
        title: 'YouTube',
        url: 'https://www.youtube.com/@standardnotes',
      },
      {
        title: 'Instagram',
        url: 'https://www.instagram.com/standardnotesprivacy/',
      },
      {
        title: 'LinkedIn',
        url: 'https://www.linkedin.com/company/standard-notes/',
      },
      {
        title: 'Reddit',
        url: 'https://reddit.com/r/standardnotes',
      },
    ],
    isAccordionItemExpanded: true,
    mobileOrder: 6,
  },

  {
    collectionTitle: 'Download',
    columnNumber: 3,
    items: [
      {
        renderableChildren: <FooterDownloadSection />,
      },
    ],
    isAccordionItemExpanded: true,
    mobileOrder: 5,
  },
  {
    columnNumber: 3,
    items: [
      {
        title: `Copyright © ${new Date().getFullYear()} · Standard Notes`,
        isLastItem: true,
      },
      {
        title: 'Privacy Policy',
        url: '/legal/privacy',
      },
      {
        title: 'Terms of Service',
        url: '/legal/terms',
      },
    ],
    isAccordionItemExpanded: true,
    hideTopBorderOnSmallScreens: true,
    mobileOrder: 8,
  },
]
