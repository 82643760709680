import { OSType } from '../typings/platforms'
import { AndroidIcon, AppleIcon, GlobeIcon, PenguinIcon, WindowsIcon } from '../assets/icons'
import { getDownloadDataForSinglePlatform } from './getDownloadDataForSinglePlatform'
import { Download, TPlatformsIdToDataMapper } from '../typings/download'

interface IParams {
  downloads: Download[]
  platformsIdToDataMapper: TPlatformsIdToDataMapper
}

export interface IGetDownloadDataReturnItem {
  id: string
  name: string
  icon: string
  link: string
}

type TGetDownloadData = ({ downloads, platformsIdToDataMapper }: IParams) => IGetDownloadDataReturnItem[]

const platformsIdToIconMapper: { [key in OSType]: string } = {
  [OSType.IOS]: AppleIcon,
  [OSType.ANDROID]: AndroidIcon,
  [OSType.WEB]: GlobeIcon,
  [OSType.MACOS]: AppleIcon,
  [OSType.MACOS_ARM]: AppleIcon,
  [OSType.WINDOWS]: WindowsIcon,
  [OSType.LINUX]: PenguinIcon,
}

export const getDownloadData: TGetDownloadData = ({ downloads, platformsIdToDataMapper }) => {
  return Object.entries(platformsIdToDataMapper)
    .sort(([_id1, platformData1], [_id2, platformData2]) => {
      return platformData1.order - platformData2.order
    })
    .map(([osId, osData]) => {
      return {
        id: osId,
        name: osData.name,
        icon: platformsIdToIconMapper[osId as OSType],
        link: (getDownloadDataForSinglePlatform(downloads, osId as OSType) as Download).link,
      }
    })
}
