import React, { FC, useContext } from 'react'
import styled from 'styled-components'
import { IGetDownloadDataReturnItem } from '../../utils/getDownloadData'
import { getScreenBreakpointSize } from '../../utils/getScreenBreakpointSize'
import { ScreenBreakpointSize } from '../../utils/enums'
import { Dropdown } from './index'
import Button from './button'
import AppDownloadsDataContext from '../../context/appDownloadsDataContext'
import { THandleDownload } from '../../utils/handleDownload'

interface IProps {
  platformItems: IGetDownloadDataReturnItem[]
  handleDownload: THandleDownload
  handlePlatformItemChange: (newPlatformItemIndex: number) => void
  className?: string
  showPlatformIcon?: boolean
}

const DownloadSectionContainer = styled.div<{ platformItem: IGetDownloadDataReturnItem | null }>`
  visibility: ${(props) => (props.platformItem === null ? 'hidden' : 'visible')};
  display: flex;

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Medium, -1)}) {
    display: block;
    width: 100%;
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
  }

  @media screen and (min-width: ${getScreenBreakpointSize(
      ScreenBreakpointSize.Medium,
    )}) and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    display: block;
    width: 100%;
    flex-direction: column;
  }
`
const CustomDropdownStyled = styled(Dropdown)`
  width: 252px;
  svg {
    height: 20px;
    width: 20px;
  }
  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Medium, -1)}) {
    width: 100%;
  }
  @media screen and (min-width: ${getScreenBreakpointSize(
      ScreenBreakpointSize.Medium,
    )}) and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    width: 100%;
  }
`
const CustomButtonStyled = styled(Button)`
  margin-left: var(--spacing-16);
  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Medium, -1)}) {
    margin-top: var(--spacing-16);
    margin-left: 0;
    width: 100%;
  }
  @media screen and (min-width: ${getScreenBreakpointSize(
      ScreenBreakpointSize.Medium,
    )}) and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    margin-top: var(--spacing-16);
    margin-left: 0;
    width: var(--button-max-width);
  }
`

const PlatformsDownload: FC<IProps> = ({
  platformItems,
  handleDownload,
  handlePlatformItemChange,
  className = '',
  showPlatformIcon = true,
}) => {
  const { currentPlatformItemIndex } = useContext(AppDownloadsDataContext)

  const platformItem = platformItems[currentPlatformItemIndex || 0]

  return (
    <DownloadSectionContainer
      platformItem={platformItem}
      className={className}
      data-name={'download-section-container'}
    >
      <CustomDropdownStyled
        options={platformItems}
        initialSelectedOptionIndex={currentPlatformItemIndex || 0}
        handleChange={handlePlatformItemChange}
        showPlatformIcon={showPlatformIcon}
      />
      {platformItem && (
        <CustomButtonStyled
          text={'Download for free'}
          handleClick={() => handleDownload(platformItem.link, `/plans?downloaded=${platformItem.id}`, platformItem.id)}
        />
      )}
    </DownloadSectionContainer>
  )
}

export default PlatformsDownload
