declare global {
  interface Window {
    plausible: (eventName: string, options?: { props: Record<string, unknown> }) => void
  }
}

type PlausibleEvent = 'Download' | 'Purchase'

export function trackPlausibleEvent(eventName: PlausibleEvent, props: Record<string, unknown>) {
  if (window.plausible) {
    window.plausible(eventName, { props })
  }
}
