import React, { FC } from 'react'
import styled from 'styled-components'
import { PaddedContentStyled } from '../../styles/shared'
import FooterColumn from './footerColumn'
import { ScreenBreakpointSize } from '../../utils/enums'
import { getScreenBreakpointSize } from '../../utils/getScreenBreakpointSize'
import FooterMobile from './footerMobile'
import { footerData } from './footerData'

const MainContainerStyled = styled.div`
  background-color: var(--background-gray);
  border-top: 1px solid var(--border-gray);

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    border-top: none;
  }
`
const ContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: var(--page-title-max-width);
  margin: var(--spacing-54) auto var(--spacing-54);

  &[data-name='small-screen'] {
    display: none;
  }

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    display: block;
    margin: 0 auto;

    &[data-name='large-screen'] {
      display: none;
    }
    &[data-name='small-screen'] {
      display: block;
    }
  }
`

const Footer: FC = () => {
  const columnData = []
  const largeScreenFooterColumnsCount = 4
  for (let i = 0; i < largeScreenFooterColumnsCount; i++) {
    columnData[i] = footerData.filter((item) => item.columnNumber === i)
  }

  return (
    <MainContainerStyled>
      <PaddedContentStyled>
        <ContainerStyled data-name={'large-screen'}>
          {columnData.map((data, index) => {
            return <FooterColumn key={index} columnData={data} />
          })}
        </ContainerStyled>
        <ContainerStyled data-name={'small-screen'}>
          <FooterMobile menuData={footerData} />
        </ContainerStyled>
      </PaddedContentStyled>
    </MainContainerStyled>
  )
}

export default Footer
