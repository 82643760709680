import { useEffect, RefObject } from 'react'

interface IProps {
  elementRef: RefObject<HTMLElement>
  callbackFn: (...args: unknown[]) => unknown
}
const useClickOutsideEventListener = ({ elementRef, callbackFn }: IProps): void => {
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
        callbackFn()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [callbackFn, elementRef])
}

export default useClickOutsideEventListener
