import { OSType } from '../typings/platforms'
import { TPlatformsIdToDataMapper } from '../typings/download'

export const getPlatformsIdToDataMapper = (
  customIdToNameMap?: Partial<TPlatformsIdToDataMapper>,
): TPlatformsIdToDataMapper => {
  const basicPlatformsIdToNameMapper = {
    [OSType.IOS]: {
      order: 0,
      name: 'iOS',
    },
    [OSType.ANDROID]: {
      order: 1,
      name: 'Android',
    },
    [OSType.MACOS]: {
      order: 2,
      name: 'macOS (Intel)',
    },
    [OSType.MACOS_ARM]: {
      order: 3,
      name: 'macOS (Apple Silicon)',
    },
    [OSType.WINDOWS]: {
      order: 4,
      name: 'Windows',
    },
    [OSType.LINUX]: {
      order: 5,
      name: 'Linux',
    },
    [OSType.WEB]: {
      order: 6,
      name: 'Web app',
    },
  }

  return {
    ...basicPlatformsIdToNameMapper,
    ...customIdToNameMap,
  }
}
