exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-compare-best-note-taking-app-tsx": () => import("./../../../src/pages/compare/best-note-taking-app.tsx" /* webpackChunkName: "component---src-pages-compare-best-note-taking-app-tsx" */),
  "component---src-pages-compare-google-keep-alternative-tsx": () => import("./../../../src/pages/compare/google-keep-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-google-keep-alternative-tsx" */),
  "component---src-pages-compare-obsidian-alternative-tsx": () => import("./../../../src/pages/compare/obsidian-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-obsidian-alternative-tsx" */),
  "component---src-pages-compare-simplenote-alternative-tsx": () => import("./../../../src/pages/compare/simplenote-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-simplenote-alternative-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-evernote-alternative-tsx": () => import("./../../../src/pages/evernote-alternative.tsx" /* webpackChunkName: "component---src-pages-evernote-alternative-tsx" */),
  "component---src-pages-help-security-tsx": () => import("./../../../src/pages/help/security.tsx" /* webpackChunkName: "component---src-pages-help-security-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-longevity-tsx": () => import("./../../../src/pages/longevity.tsx" /* webpackChunkName: "component---src-pages-longevity-tsx" */),
  "component---src-pages-offline-tsx": () => import("./../../../src/pages/offline.tsx" /* webpackChunkName: "component---src-pages-offline-tsx" */),
  "component---src-pages-permissions-tsx": () => import("./../../../src/pages/permissions.tsx" /* webpackChunkName: "component---src-pages-permissions-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-principles-tsx": () => import("./../../../src/pages/principles.tsx" /* webpackChunkName: "component---src-pages-principles-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-reset-confirm-tsx": () => import("./../../../src/pages/reset/confirm.tsx" /* webpackChunkName: "component---src-pages-reset-confirm-tsx" */),
  "component---src-pages-reset-emailed-tsx": () => import("./../../../src/pages/reset/emailed.tsx" /* webpackChunkName: "component---src-pages-reset-emailed-tsx" */),
  "component---src-pages-reset-finalize-tsx": () => import("./../../../src/pages/reset/finalize.tsx" /* webpackChunkName: "component---src-pages-reset-finalize-tsx" */),
  "component---src-pages-reset-index-tsx": () => import("./../../../src/pages/reset/index.tsx" /* webpackChunkName: "component---src-pages-reset-index-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-usecase-journalism-tsx": () => import("./../../../src/pages/usecase/journalism.tsx" /* webpackChunkName: "component---src-pages-usecase-journalism-tsx" */),
  "component---src-pages-usecase-legal-tsx": () => import("./../../../src/pages/usecase/legal.tsx" /* webpackChunkName: "component---src-pages-usecase-legal-tsx" */),
  "component---src-pages-usecase-writers-tsx": () => import("./../../../src/pages/usecase/writers.tsx" /* webpackChunkName: "component---src-pages-usecase-writers-tsx" */),
  "component---src-pages-why-encrypted-tsx": () => import("./../../../src/pages/why-encrypted.tsx" /* webpackChunkName: "component---src-pages-why-encrypted-tsx" */),
  "component---src-templates-account-claim-account-claim-tsx": () => import("./../../../src/templates/account-claim/account-claim.tsx" /* webpackChunkName: "component---src-templates-account-claim-account-claim-tsx" */),
  "component---src-templates-account-claim-success-tsx": () => import("./../../../src/templates/account-claim/success.tsx" /* webpackChunkName: "component---src-templates-account-claim-success-tsx" */),
  "component---src-templates-blog-blog-index-tsx": () => import("./../../../src/templates/blog/blog-index.tsx" /* webpackChunkName: "component---src-templates-blog-blog-index-tsx" */),
  "component---src-templates-blog-blog-post-tsx": () => import("./../../../src/templates/blog/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-blog-post-tsx" */),
  "component---src-templates-demo-tsx": () => import("./../../../src/templates/demo.tsx" /* webpackChunkName: "component---src-templates-demo-tsx" */),
  "component---src-templates-download-tsx": () => import("./../../../src/templates/download.tsx" /* webpackChunkName: "component---src-templates-download-tsx" */),
  "component---src-templates-features-tsx": () => import("./../../../src/templates/features.tsx" /* webpackChunkName: "component---src-templates-features-tsx" */),
  "component---src-templates-help-help-tsx": () => import("./../../../src/templates/help/help.tsx" /* webpackChunkName: "component---src-templates-help-help-tsx" */),
  "component---src-templates-help-post-tsx": () => import("./../../../src/templates/help/post.tsx" /* webpackChunkName: "component---src-templates-help-post-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-knowledge-knowledge-tsx": () => import("./../../../src/templates/knowledge/knowledge.tsx" /* webpackChunkName: "component---src-templates-knowledge-knowledge-tsx" */),
  "component---src-templates-knowledge-post-tsx": () => import("./../../../src/templates/knowledge/post.tsx" /* webpackChunkName: "component---src-templates-knowledge-post-tsx" */),
  "component---src-templates-plans-tsx": () => import("./../../../src/templates/plans.tsx" /* webpackChunkName: "component---src-templates-plans-tsx" */),
  "component---src-templates-purchase-dashboard-email-sent-tsx": () => import("./../../../src/templates/purchase/dashboard-email-sent.tsx" /* webpackChunkName: "component---src-templates-purchase-dashboard-email-sent-tsx" */),
  "component---src-templates-purchase-stripe-redirect-tsx": () => import("./../../../src/templates/purchase/stripe-redirect.tsx" /* webpackChunkName: "component---src-templates-purchase-stripe-redirect-tsx" */),
  "component---src-templates-purchase-success-tsx": () => import("./../../../src/templates/purchase/success.tsx" /* webpackChunkName: "component---src-templates-purchase-success-tsx" */),
  "component---src-templates-purchase-wizard-tsx": () => import("./../../../src/templates/purchase/wizard.tsx" /* webpackChunkName: "component---src-templates-purchase-wizard-tsx" */),
  "component---src-templates-subscription-dashboard-dashboard-tsx": () => import("./../../../src/templates/subscription_dashboard/dashboard.tsx" /* webpackChunkName: "component---src-templates-subscription-dashboard-dashboard-tsx" */)
}

