import React, { FC } from 'react'
import styled from 'styled-components'
import CarouselItem from './carouselItem'

interface ICarouselItem {
  icon: string
  text: string
  iconHoverColor: string
}
interface IProps {
  data: ICarouselItem[]
}

const ContainerStyled = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: var(--spacing-24);
`
const CarouselWrapperStyled = styled.div`
  display: flex;
`

const Carousel: FC<IProps> = ({ data }) => {
  return (
    <ContainerStyled>
      <CarouselWrapperStyled className="flex flex-row flex-wrap justify-center">
        {data.map((carouselItem, index) => (
          <CarouselItem key={index} {...carouselItem} />
        ))}
      </CarouselWrapperStyled>
    </ContainerStyled>
  )
}

export default Carousel
