import React, { FC, useRef, useState } from 'react'
import styled from 'styled-components'
import { Location } from '@reach/router'
import SVG from 'react-inlinesvg'
import { SNLogoIcon } from '../../assets/icons'
import HeaderNavigation from './headerNavigation'
import Link from '../link/Link'
import useControlHeaderItems from '../../hooks/useControlHeaderItems'
import { ScreenBreakpointSize } from '../../utils/enums'
import { getScreenBreakpointSize } from '../../utils/getScreenBreakpointSize'
import { PaddedContentStyled } from '../../styles/shared'
import HamburgerMenu from './hamburgerMenu'
import { HorizontalSeparator } from '../common'
import { FontWeight600 } from '../../styles/variables'

const StickyContainerStyled = styled.div<{ shouldShowBorder: boolean }>`
  background-color: ${(props) => (props.shouldShowBorder ? 'var(--white)' : 'none')};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    background-color: var(--white);
    border-bottom: 1px solid var(--border-gray);
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
  }
`
const ContainerStyled = styled.div`
  display: flex;
  align-items: center;

  height: var(--spacing-72);
  max-width: var(--page-title-max-width);
  margin: 0 auto;

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    height: var(--header-height-mobile);
    z-index: 1;
    position: relative;
  }
`

const SNLogoStyled = styled(SVG)`
  width: 100%;

  path {
    fill: var(--color-denim);
  }
`

const LogoContainer = styled.div`
  width: 25px;
  height: 25px;

  path {
    fill: var(--color-denim);
  }
`

const SNName = styled.h3`
  color: var(--color-mariner);
  font-weight: ${FontWeight600};
  margin-left: var(--spacing-8);
  min-width: max-content;

  &:not(:first-child) {
    margin-top: 0;
    margin-bottom: 0;
  }
`
const CustomHorizontalSeparatorStyled = styled(HorizontalSeparator)<{ shouldShowBorder: boolean }>`
  opacity: ${(props) => (props.shouldShowBorder ? 1 : 0)};
  transition: all ease-in 0.3s;

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    display: none;
  }
`

const Header: FC = () => {
  const headerTopMostElementRef = useRef(null)
  const [shouldShowAllHeaderItems, setShouldShowAllHeaderItems] = useState(false)

  useControlHeaderItems({
    headerTopMostElementRef,
    setShouldShowAllHeaderItems,
  })

  return (
    <Location>
      {({ location = { pathname: '' } }) => {
        const { pathname } = location
        const pathnameWithoutTrailingSlash = pathname.endsWith('/')
          ? pathname.substring(0, pathname.lastIndexOf('/'))
          : pathname
        const path = pathnameWithoutTrailingSlash.split('/').pop()

        return (
          <>
            <div ref={headerTopMostElementRef} />
            <StickyContainerStyled shouldShowBorder={shouldShowAllHeaderItems}>
              <PaddedContentStyled>
                <ContainerStyled>
                  <LogoContainer>
                    <SNLogoStyled src={SNLogoIcon} />
                  </LogoContainer>
                  <SNName>
                    <Link to={'/'}>Standard Notes</Link>
                  </SNName>
                  <HeaderNavigation shouldShowAllHeaderItems={shouldShowAllHeaderItems} path={path} />
                  <HamburgerMenu path={path} />
                </ContainerStyled>
              </PaddedContentStyled>
              <CustomHorizontalSeparatorStyled shouldShowBorder={shouldShowAllHeaderItems} />
            </StickyContainerStyled>
          </>
        )
      }}
    </Location>
  )
}

export default Header
