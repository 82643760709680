import React, { ButtonHTMLAttributes, FC } from 'react'
import styled from 'styled-components'
import { FontWeight600 } from '../../styles/variables'
import Link from '../link/Link'

interface IProps {
  text: string
  handleClick?: (...args: unknown[]) => unknown
  className?: string
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  disabled?: boolean
}

const ContainerStyled = styled.button`
  background: var(--color-blue);
  padding: var(--spacing-12) var(--spacing-16);
  color: var(--white);
  font-size: var(--font-size-16);
  font-family: var(--avenir-next);
  font-weight: ${FontWeight600};
  height: auto;

  &:hover {
    text-decoration: underline;
  }

  &[disabled] {
    background-color: var(--color-black-opacity-10);
    color: var(--color-white);
    cursor: not-allowed;
    text-decoration: none;
  }
`

const Button: FC<IProps> = ({
  text,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClick = () => {},
  className = '',
  type = 'button',
  disabled,
}) => {
  return (
    <ContainerStyled className={className} onClick={handleClick} type={type} disabled={disabled} data-name={'button'}>
      {text}
    </ContainerStyled>
  )
}

interface ILinkButtonProps {
  text: string
  href: string
}

const StyledLinkButton = styled(ContainerStyled)`
  border-radius: 0.25rem;
  min-width: 8.5rem;
`

export const LinkButton: FC<ILinkButtonProps> = ({ text, href }) => {
  return (
    <StyledLinkButton as={Link} to={href}>
      {text}
    </StyledLinkButton>
  )
}

export const ButtonDanger = styled(Button)`
  background-color: var(--red-color);
`

export const ButtonBorderOnly = styled(Button)`
  background-color: transparent;
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
`

export default Button
