import { RemoteDownloadData } from '../typings/download'

import Releases from '@standardnotes/releases/dist/releases.json'
import { IGetDownloadDataReturnItem } from '../utils/getDownloadData'

const releaseDownloads = Releases.Downloads
const releaseVersions = Releases.Versions

const GooglePlay = 'https://play.google.com/store/apps/details?id=com.standardnotes'
const AppStoreiOS = 'https://itunes.apple.com/us/app/standard-notes/id1285392450?mt=8'
const WebApp = 'https://app.standardnotes.com'
const MobileVersion = releaseVersions['@standardnotes/mobile']
const AndroidApk = `https://github.com/standardnotes/app/releases/download/%40standardnotes%2Fmobile%40${MobileVersion}/app-prod-release.apk`

const WebAppDownloadItem = {
  name: 'Web',
  icon: 'ico-chrome.png',
  link: WebApp,
  icon_class: 'icon-safari',
  platform: 'web',
}

export const DemoDownloadItem: IGetDownloadDataReturnItem = {
  name: 'Demo',
  icon: 'ico-chrome.png',
  link: 'https://standardnotes.com/demo',
  id: 'demo',
}

const MacIntelDownloadItem = {
  name: 'macOS (Intel)',
  icon: 'ico-apple.png',
  app_icon: 'app-icon-blue.png',
  link: releaseDownloads['MacX64Dmg'],
  icon_class: 'icon-apple-brands',
  platform: 'mac-intel',
}

export const Downloads: RemoteDownloadData = {
  android_apk: AndroidApk,
  platformItem: MacIntelDownloadItem,
  downloads: [
    {
      section: 'Desktop',
      apps: [
        MacIntelDownloadItem,
        {
          name: 'macOS (Apple Silicon)',
          icon: 'ico-apple.png',
          app_icon: 'app-icon-blue.png',
          link: releaseDownloads['MacArm64Dmg'],
          icon_class: 'icon-apple-brands',
          platform: 'mac-arm',
        },
        {
          name: 'Windows',
          icon: 'ico-windows.png',
          app_icon: 'app-icon-blue.png',
          link: releaseDownloads['WinX64Exe'],
          icon_class: 'icon-windows-brands',
          platform: 'windows',
        },
        {
          name: 'Linux',
          icon: 'ico-linux.png',
          app_icon: 'app-icon-blue.png',
          link: releaseDownloads['LinuxX86_64AppImage'],
          link_32: releaseDownloads['LinuxI386AppImage'],
          icon_class: 'icon-linux-brands',
          platform: 'linux',
        },
      ],
    },
    {
      section: 'Mobile',
      apps: [
        {
          name: 'iOS',
          icon: 'ico-apple.png',
          app_icon: 'app-icon-blue.png',
          link: AppStoreiOS,
          icon_class: 'icon-apple-brands',
          platform: 'ios',
        },
        {
          name: 'Android',
          icon: 'ico-android.png',
          app_icon: 'app-icon-blue.png',
          link: GooglePlay,
          icon_class: 'icon-android-brands',
          platform: 'android',
        },
      ],
    },
    {
      section: 'Web',
      apps: [WebAppDownloadItem],
    },
  ],
  list: [
    {
      name: 'macOS (Intel)',
      icon: 'ico-apple.png',
      app_icon: 'app-icon-blue.png',
      link: releaseDownloads['MacX64Dmg'],
      icon_class: 'icon-apple-brands',
      platform: 'mac-intel',
    },
    {
      name: 'macOS (Apple Silicon)',
      icon: 'ico-apple.png',
      app_icon: 'app-icon-blue.png',
      link: releaseDownloads['MacArm64Dmg'],
      icon_class: 'icon-apple-brands',
      platform: 'mac-arm',
    },
    {
      name: 'Windows',
      icon: 'ico-windows.png',
      app_icon: 'app-icon-blue.png',
      link: releaseDownloads['WinX64Exe'],
      icon_class: 'icon-windows-brands',
      platform: 'windows',
    },
    {
      name: 'Linux',
      icon: 'ico-linux.png',
      app_icon: 'app-icon-blue.png',
      link: releaseDownloads['LinuxX86_64AppImage'],
      link_32: releaseDownloads['LinuxI386AppImage'],
      icon_class: 'icon-linux-brands',
      platform: 'linux',
    },
    {
      name: 'iOS',
      icon: 'ico-apple.png',
      app_icon: 'app-icon-blue.png',
      link: AppStoreiOS,
      icon_class: 'icon-apple-brands',
      platform: 'ios',
    },
    {
      name: 'Android',
      icon: 'ico-android.png',
      app_icon: 'app-icon-blue.png',
      link: GooglePlay,
      icon_class: 'icon-android-brands',
      platform: 'android',
    },
    {
      name: 'Web',
      icon: 'ico-chrome.png',
      link: WebApp,
      icon_class: 'icon-safari',
      platform: 'web',
    },
  ],
}

export function DownloadsWithUserRecommendedDownload(
  _currentBrowser: string,
  currentPlatformName: string,
): RemoteDownloadData {
  const recommendedDownload = Downloads.list.find((download) => download.platform === currentPlatformName)

  return {
    ...Downloads,
    platformItem: recommendedDownload,
  }
}
