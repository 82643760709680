import React, { FC } from 'react'
import { AccordionItem } from '../common'
import { IFooterGroupedItems } from '../../typings/footer'
import styled from 'styled-components'
import Link from '../link/Link'
import { FontWeight500, FontWeight700 } from '../../styles/variables'

interface IProps {
  menuData: IFooterGroupedItems[]
}

const CollectionContainerStyled = styled.div`
  border-top: 1px solid var(--border-gray);
  padding: var(--spacing-24) var(--spacing-16);

  &[data-hide-top-border='true'] {
    border-top: none;
    padding-top: 0;
  }

  [data-name='title'] {
    color: var(--body-gray);
    font-size: var(--font-size-16);
    font-weight: ${FontWeight700};
    line-height: var(--line-height-1_6);

    &:hover {
      &[data-is-always-expanded='false'] {
        cursor: pointer;
      }
    }
  }

  [data-name='expandable-section'] {
    overflow: visible; /* to make downloads dropdown visible */

    &[data-expanded='true'] {
      padding-top: var(--spacing-12);
    }
  }
`

const TitleStyled = styled.div`
  display: flex;
  align-items: center;
`
const SingleItemContainerStyled = styled.div`
  display: flex;
  align-items: center;
`
const LinkStyled = styled(Link)`
  color: var(--body-gray);
  display: block;
  font-size: var(--font-size-16);
  font-weight: ${FontWeight500};
  line-height: var(--line-height-2_4);
`
const TextWithoutLinkStyled = styled.div`
  color: var(--heading-gray);
  font-size: var(--font-size-16);
  font-weight: ${FontWeight500};
  line-height: var(--line-height-1_5);
  max-width: 362px;
`
const AdditionalTextStyled = styled.div`
  background-color: var(--corn);
  border-radius: var(--border-radius-2);
  color: var(--black);
  font-size: var(--font-size-10);
  font-weight: ${FontWeight700};
  margin-left: var(--spacing-8);
  padding: var(--spacing-2) var(--spacing-4) var(--spacing-1);
  height: min-content;
`

const titleRenderer = ({ title = '', additionalText }: { title?: string; additionalText?: string }) => (
  <TitleStyled>
    {title}
    {additionalText && <AdditionalTextStyled>{additionalText}</AdditionalTextStyled>}
  </TitleStyled>
)

const FooterMobile: FC<IProps> = ({ menuData }) => {
  return (
    <>
      {[...menuData]
        .sort((item1, item2) => {
          return item1.mobileOrder - item2.mobileOrder
        })
        .map((menuSingleCollection, index) => {
          const {
            collectionTitle,
            items,
            hideTitleOnSmallScreens,
            hideTopBorderOnSmallScreens,
            isAccordionItemExpanded,
          } = menuSingleCollection

          const itemWithAdditionalText = items.find((item) => item.additionalText)

          const title = hideTitleOnSmallScreens
            ? ''
            : titleRenderer({
                title: collectionTitle,
                additionalText: itemWithAdditionalText?.additionalText,
              })

          return (
            <CollectionContainerStyled key={`small-screen-${index}`} data-hide-top-border={hideTopBorderOnSmallScreens}>
              <AccordionItem title={title} isAlwaysExpanded={isAccordionItemExpanded}>
                {items.map(({ title = '', url = '', renderableChildren = null }, index) => {
                  return (
                    <SingleItemContainerStyled key={index}>
                      {title &&
                        (url ? (
                          <LinkStyled to={url}>{title}</LinkStyled>
                        ) : (
                          <TextWithoutLinkStyled data-name="title-without-link">{title}</TextWithoutLinkStyled>
                        ))}
                      {renderableChildren}
                    </SingleItemContainerStyled>
                  )
                })}
              </AccordionItem>
            </CollectionContainerStyled>
          )
        })}
    </>
  )
}

export default FooterMobile
