import { planToSubscriptionNameMap } from './planToSubscriptionNameMap'
import { SubscriptionName } from '@standardnotes/common'
import { ChoosePricingPlanButtonText } from './enums'
import { DisplayableSubscriptionPlanIdentifier, FreeSubscriptionPlanName } from '../typings/plans'

const corePlanName = planToSubscriptionNameMap.get(FreeSubscriptionPlanName) as string
const plusPlanName = planToSubscriptionNameMap.get(SubscriptionName.PlusPlan) as string
const proPlanName = planToSubscriptionNameMap.get(SubscriptionName.ProPlan) as string

const { PlusPlan, ProPlan } = SubscriptionName

type PlanDisplayType = {
  name: string
  identifier: DisplayableSubscriptionPlanIdentifier
  actionText: string
}

export const PlanDisplayList: PlanDisplayType[] = [
  {
    name: corePlanName,
    identifier: FreeSubscriptionPlanName,
    actionText: ChoosePricingPlanButtonText.ChooseCore,
  },
  {
    name: plusPlanName,
    identifier: PlusPlan,
    actionText: ChoosePricingPlanButtonText.GoPlus,
  },
  {
    name: proPlanName,
    identifier: ProPlan,
    actionText: ChoosePricingPlanButtonText.ChoosePro,
  },
]

export const SN_FOUNDATION_DATE = {
  year: 2016,
  month: 11,
}

export const DROPDOWN_NAME_AND_OPTIONS_VERTICAL_DISTANCE = 8

export const HEADER_NAVIGATION_ITEMS = [
  {
    name: 'Pricing',
    url: 'plans',
    isUnfinishedFeature: false,
  },
  {
    name: 'Features',
    url: 'features',
    isUnfinishedFeature: false,
  },
  {
    name: 'Help',
    url: 'help',
    isUnfinishedFeature: false,
  },
]

export const PLANS_COMPARISON_LEFT_COLUMN_WIDTH_IN_PERCENTS_MOBILE = 30
export const PLANS_COMPARISON_RIGHT_COLUMN_WIDTH_IN_PERCENTS_MOBILE = 55
export const PLANS_COMPARISON_COLUMN_WITH_TOPIC_NAME_WIDTH = 417
