import React from 'react'
import Link from '../link/Link'
import { ArrowRightIcon } from '../../assets/icons'
import styled from 'styled-components'
import { FontWeight600 } from '../../styles/variables'

interface IProps {
  text: string
  targetUrl: string
  className?: string
  showArrow?: boolean
}

const TextWithLinkContainer = styled.span`
  display: flex;
  font-size: var(--font-size-15);
  font-weight: ${FontWeight600};
  /*
     Images are not underlined when they are in <a> tags, so we use a little trick to make it work
     https://stackoverflow.com/a/48693294/2504429
    */
  &:hover {
    [data-name='link-arrow-image-container'] {
      &::after {
        bottom: 0;
        content: '\\00A0';
        left: 0;
        letter-spacing: 19px;
        position: absolute;
        right: 0;
        text-decoration: underline;
        top: 0;
      }
    }
  }
`

const LinkArrowImageContainerStyled = styled.span`
  padding-left: var(--spacing-4);
  position: relative;
`
const TextWithLinkStyled = ({ text, targetUrl, className = '', showArrow = true }: IProps): JSX.Element => {
  return (
    <Link to={targetUrl}>
      <TextWithLinkContainer className={className}>
        {text}
        {showArrow && (
          <LinkArrowImageContainerStyled data-name="link-arrow-image-container">
            <img alt="Right Arrow Icon" data-name="link-arrow-image" src={ArrowRightIcon} />
          </LinkArrowImageContainerStyled>
        )}
      </TextWithLinkContainer>
    </Link>
  )
}

export default TextWithLinkStyled
