import React, { FC } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

interface IProps {
  text: string
  icon: string
  iconHoverColor: string
}

const CustomIconContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  height: 20px;
  width: 20px;
`
const ContainerStyled = styled.div<Pick<IProps, 'iconHoverColor'>>`
  background-color: white;
  border-radius: var(--border-radius-8);
  border: 1px solid var(--border-gray);
  color: var(--body-gray);
  font-size: var(--font-size-12);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 6px;
  padding: 10px;

  ${CustomIconContainerStyled} {
    transition: background-color 0.8s ease-in-out;
  }

  path {
    fill: ${(props) => props.iconHoverColor};
  }
`

const CarouselItem: FC<IProps> = ({ text, icon, iconHoverColor }) => {
  return (
    <ContainerStyled iconHoverColor={iconHoverColor}>
      <CustomIconContainerStyled>
        <SVG src={icon} />
      </CustomIconContainerStyled>
      {text}
    </ContainerStyled>
  )
}

export default CarouselItem
