import * as Bowser from 'bowser'
import { BrowserType, OSType } from '../typings/platforms'

interface IReturnType {
  currentBrowser: string
  currentPlatformName: string
}

export const getCurrentPlatformAndBrowser = (): IReturnType => {
  let OSName: string = OSType.WEB
  let browserName: string = BrowserType.SAFARI

  if (typeof window === 'undefined') {
    return {
      currentBrowser: browserName,
      currentPlatformName: OSName,
    }
  }

  const bowser = Bowser.getParser(window.navigator.userAgent)
  OSName = bowser.getOSName().toLowerCase()
  if (OSName === 'macos') {
    OSName = OSType.MACOS
  }
  if (!Object.values(OSType).some((value) => value === OSName)) {
    OSName = OSType.WEB
  }

  browserName = bowser.getBrowserName().toLowerCase()
  if (!Object.values(BrowserType).some((browserType) => browserType === browserName)) {
    browserName = BrowserType.SAFARI
  }

  return {
    currentBrowser: browserName,
    currentPlatformName: OSName,
  }
}
