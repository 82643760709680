import React, { createContext, FC, ReactNode, useState } from 'react'
import { Downloads } from '../data/Downloads'
import { DownloadSelectionState } from '../typings/download'

interface IProps {
  children?: ReactNode
}

const appDownloadsDataDefaultValue: DownloadSelectionState = {
  ...Downloads,
  currentPlatformItemIndex: null,
}

const AppDownloadsDataContext = createContext({
  ...appDownloadsDataDefaultValue,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateAppDownloadsData: (_newDownloadsData: Partial<DownloadSelectionState>) => {},
})

AppDownloadsDataContext.displayName = 'AppDownloadsDataContext'

export const AppDownloadsDataContextProvider: FC<IProps> = ({ children }) => {
  const [appDownloadsData, setAppDownloadsData] = useState(appDownloadsDataDefaultValue)

  return (
    <AppDownloadsDataContext.Provider
      value={{
        ...appDownloadsData,
        updateAppDownloadsData: (newDownloadsData) => {
          setAppDownloadsData({ ...appDownloadsData, ...newDownloadsData })
        },
      }}
    >
      {children}
    </AppDownloadsDataContext.Provider>
  )
}

export default AppDownloadsDataContext
