import React, { FC } from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
}

const ContainerStyled = styled.div`
  position: absolute;
  width: 100%;
  background: linear-gradient(180deg, var(--background-gray) 60%, rgba(248, 249, 252, 0) 100%);
  height: 360px;
  top: -5%;
`

const SectionWithGradientBackground: FC<IProps> = ({ className = '' }) => {
  return <ContainerStyled className={className} />
}

export default SectionWithGradientBackground
