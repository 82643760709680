import React, { FC } from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
}

const SeparatorStyled = styled.div`
  background: var(--border-gray);
  height: 1px;
  width: 100%;
`

const HorizontalSeparator: FC<IProps> = ({ className = '' }) => {
  return <SeparatorStyled className={className} />
}

export default HorizontalSeparator
