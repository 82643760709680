import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FilledArrowDownIcon } from '../../assets/icons'
import SVG from 'react-inlinesvg'
import useClickOutsideEventListener from '../../hooks/useClickOutsideEventListener'
import { getCanOpenDropdownOnBottom } from '../../utils/getCanOpenDropdownOnBottom'
import { DROPDOWN_NAME_AND_OPTIONS_VERTICAL_DISTANCE } from '../../utils/constants'
import { FontWeight500 } from '../../styles/variables'

interface IOptionItem {
  name: string
  icon: string
}

interface IProps {
  options: IOptionItem[]
  handleChange: (selectedOptionIndex: number) => void
  className?: string
  initialSelectedOptionIndex?: number
  showPlatformIcon: boolean
}

const MainContainerStyled = styled.div`
  color: var(--black);
  position: relative;
  font-size: var(--font-size-16);
  font-weight: ${FontWeight500};
  line-height: var(--line-height-1_5);
`
const ContainerStyled = styled.div`
  background-color: var(--white);
  cursor: pointer;
  border: 1px solid var(--border-gray);
  border-radius: var(--border-radius-4);
  padding: var(--spacing-12) var(--spacing-12) var(--spacing-12) var(--spacing-16);
  position: relative;
  z-index: 1;
`
const NameContainerStyled = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 1.5rem;

  path {
    fill: var(--black);
  }

  [data-name='arrow-container'] {
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : '')};
  }
`
const NameStyled = styled.div`
  margin-left: var(--spacing-16);
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ArrowContainerStyled = styled(SVG)`
  position: absolute;
  right: 0;
`
const OptionsContainerStyled = styled.div<{
  isOpen: boolean
  shouldOpenDropdownOnBottom: boolean
  dropdownOptionsBottomDistance: number
}>`
  background-color: var(--white);
  border-radius: var(--border-radius-4);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
  margin-top: var(--spacing-4);
  padding: var(--spacing-8) 0;
  position: absolute;
  bottom: ${(props) => {
    return props.isOpen && props.shouldOpenDropdownOnBottom ? 'auto' : `${props.dropdownOptionsBottomDistance}px`
  }};
  width: 100%;
  z-index: 1;
`
const OptionItemContainerStyled = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: var(--spacing-12) var(--spacing-16);

  background-color: ${(props) => (props.isSelected ? 'var(--color-mariner-opacity-8)' : 'var(--white)')};
  color: ${(props) => (props.isSelected ? 'var(--color-blue)' : 'var(--black)')};

  svg {
    flex-shrink: 0;
  }

  path {
    fill: ${(props) => (props.isSelected ? 'var(--color-blue)' : 'var(--black)')};
  }

  &:hover {
    background-color: var(--athens-gray);
  }
`

const Dropdown: FC<IProps> = ({
  options,
  handleChange,
  className = '',
  initialSelectedOptionIndex = 0,
  showPlatformIcon,
}) => {
  const dropdownContainerRef = useRef(null)
  const dropdownNameContainerRef = useRef<HTMLDivElement | null>(null)
  const dropdownOptionsRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(() => initialSelectedOptionIndex)

  useEffect(() => {
    setSelectedOptionIndex(initialSelectedOptionIndex)
  }, [initialSelectedOptionIndex])

  useClickOutsideEventListener({
    elementRef: dropdownContainerRef,
    callbackFn: () => setIsOpen(false),
  })

  const [shouldOpenDropdownOnBottom, setShouldOpenDropdownOnBottom] = useState(true)
  const [dropdownOptionsBottomDistance, setDropdownOptionsBottomDistance] = useState(0)

  useEffect(() => {
    const canOpenOnBottom = getCanOpenDropdownOnBottom({
      dropdownContainerRef,
      dropdownOptionsRef,
    })

    if (isOpen) {
      const dropdownElementBoundingRect = (dropdownNameContainerRef.current as HTMLDivElement).getBoundingClientRect()
      const dropdownElementHeight = dropdownElementBoundingRect.bottom - dropdownElementBoundingRect.top

      setDropdownOptionsBottomDistance(dropdownElementHeight + DROPDOWN_NAME_AND_OPTIONS_VERTICAL_DISTANCE)
      setShouldOpenDropdownOnBottom(canOpenOnBottom)
    }
  }, [isOpen])

  return (
    <MainContainerStyled ref={dropdownContainerRef} data-name="dropdown" className={className}>
      <ContainerStyled
        ref={dropdownNameContainerRef}
        onClick={() => setIsOpen(!isOpen)}
        data-name={'chosen-item-container'}
      >
        <NameContainerStyled isOpen={isOpen}>
          {showPlatformIcon && <SVG src={options[selectedOptionIndex].icon} data-name={'icon'} />}
          <NameStyled data-name="name" title={options[selectedOptionIndex].name}>
            {options[selectedOptionIndex].name}
          </NameStyled>
          <ArrowContainerStyled data-name="arrow-container" src={FilledArrowDownIcon} />
        </NameContainerStyled>
      </ContainerStyled>
      {isOpen && (
        <OptionsContainerStyled
          data-name={'options-container'}
          isOpen={isOpen}
          ref={dropdownOptionsRef}
          shouldOpenDropdownOnBottom={shouldOpenDropdownOnBottom}
          dropdownOptionsBottomDistance={dropdownOptionsBottomDistance}
        >
          {options.map(({ icon, name }, index) => (
            <OptionItemContainerStyled
              key={name}
              isSelected={selectedOptionIndex === index}
              onClick={() => {
                setSelectedOptionIndex(index)
                handleChange(index)
                setIsOpen(false)
              }}
              title={name}
            >
              <SVG src={icon} />
              <NameStyled>{name}</NameStyled>
            </OptionItemContainerStyled>
          ))}
        </OptionsContainerStyled>
      )}
    </MainContainerStyled>
  )
}

export default Dropdown
