import { navigate } from 'gatsby'
import { OSType } from '../typings/platforms'
import { trackPlausibleEvent } from './plausible'

export type THandleDownload = (link: string, successUrl: string, platform: string) => void

export const handleDownload: THandleDownload = (link, successUrl, platform) => {
  const isRedirecting = platform === OSType.IOS || platform === OSType.ANDROID || platform === OSType.WEB

  trackPlausibleEvent('Download', { platform })

  setTimeout(
    function () {
      window.location.href = link
    },
    isRedirecting ? 2000 : 500,
  )

  void navigate(successUrl)
}
