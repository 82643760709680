import React, { useState } from 'react'
import styled from 'styled-components'
import { ToggleOffIcon, ToggleOnIcon } from '../../assets/icons'
import SVG from 'react-inlinesvg'

interface IProps {
  isEnabled?: boolean
  handleChange: (params?: unknown) => void
  shouldOnlyMirrorToggleImage?: boolean
  className?: string
  defaultValue?: 'left' | 'right'
}

const ToggleStyled = styled(SVG)`
  fill: var(--color-blue);
`

const Toggle = ({
  isEnabled = true,
  handleChange,
  shouldOnlyMirrorToggleImage = false,
  className = '',
  defaultValue = 'left',
}: IProps): JSX.Element => {
  const [isIconMirrored, setIsIconMirrored] = useState(defaultValue == 'left')

  let toggleIcon = ToggleOnIcon
  const mirroredClass = isIconMirrored ? 'mirror-horizontally' : ''

  if (!shouldOnlyMirrorToggleImage && !isEnabled) {
    toggleIcon = ToggleOffIcon
  }

  const onClick = (...args: unknown[]) => {
    if (shouldOnlyMirrorToggleImage) {
      setIsIconMirrored(!isIconMirrored)
    }
    handleChange(args)
  }

  return (
    <div className={`${className} ${mirroredClass} pointer`} onClick={() => isEnabled && onClick()}>
      <ToggleStyled src={toggleIcon} width={46} height={25} />
    </div>
  )
}

export default Toggle
