import { PlatformsDownload } from '../common'
import React, { FC, useContext } from 'react'
import { handleDownload } from '../../utils/handleDownload'
import { getDownloadData } from '../../utils/getDownloadData'
import AppDownloadsDataContext from '../../context/appDownloadsDataContext'
import { getPlatformsIdToDataMapper } from '../../utils/getPlatformsIdToDataMapper'
import styled from 'styled-components'
import { ScreenBreakpointSize } from '../../utils/enums'
import { getScreenBreakpointSize } from '../../utils/getScreenBreakpointSize'
import { TPlatformPartialItem } from '../../typings/download'

const PlatformsDownloadStyled = styled(PlatformsDownload)`
  [data-name='name'] {
    margin-left: 0;
  }

  @media screen and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Medium, -1)}) {
    padding-left: 0;
    padding-right: 0;

    [data-name='dropdown'] {
      margin-top: var(--spacing-16);
      max-width: var(--button-max-width);
      width: 100%;
    }

    Button {
      max-width: var(--button-max-width);
    }
  }

  @media screen and (min-width: ${getScreenBreakpointSize(
      ScreenBreakpointSize.Medium,
    )}) and (max-width: ${getScreenBreakpointSize(ScreenBreakpointSize.Large, -1)}) {
    &[data-name='download-section-container'] {
      display: flex;
      margin-top: var(--spacing-16);
    }

    Button {
      width: 100%;
    }
  }
`

const FooterDownloadSection: FC = () => {
  const { list, updateAppDownloadsData } = useContext(AppDownloadsDataContext)

  const platformItems = getDownloadData({
    downloads: list,
    platformsIdToDataMapper: getPlatformsIdToDataMapper(),
  })

  const handlePlatformItemChange = (newPlatformItemIndex: number) => {
    updateAppDownloadsData({
      currentPlatformItemIndex: newPlatformItemIndex,
      platformItem: platformItems[newPlatformItemIndex] as TPlatformPartialItem,
    })
  }

  return (
    <PlatformsDownloadStyled
      platformItems={platformItems}
      handleDownload={handleDownload}
      handlePlatformItemChange={handlePlatformItemChange}
      showPlatformIcon={false}
    />
  )
}

export default FooterDownloadSection
